<template>
  <SedeEstado
    titulo="Reportes de Inventario - Vencimeintos"
    :dashboard="dashboard"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
    tipo="vencimiento"
  />
</template>
  
<script>
import SedeEstado from "../components/SedeEstado.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteVencimientoLayout",
  components: {
    SedeEstado,
  },
  props: {
    dashboard: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      componenteListado: "ListadoVencimiento",
    };
  },
  methods: {
    servicioGetReporte:
      ReporteInventarioService.getReporteInventarioVencimiento,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioVencimientoPdf,
  },
};
</script>
  